.navbar {
    padding-top: 20px;
    padding-bottom: 20px;
}

.navLink {
    font-size: 18px;
    line-height: 23px;
    a {
        color: #1F1534;
        &:hover {
            opacity: 0.8;
            text-decoration: underline;
        }
    }
    &:not(:last-child) {
        padding-right: 51px;
    }
}

.navTitle {
    color: black;
    > span {
        vertical-align: middle;
    }
}