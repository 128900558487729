.wrapper {
    padding: 30px 36px;
    text-align: center;
    background: #FFFFFF;
    /* drop shadow */
    box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);
    border-radius: 20px;
    border: 1px solid #f1f2f3;
}

.text {
    font-weight: bold;
    font-size: 24px;
}