
.card {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.cardBlock {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem 1rem;
}

:global(.card).card {
    border-radius: 0;
    border: 0;
    margin-bottom: 10px;
    color: white;
    transition: opacity 0.5s ease;
    &:hover {
        opacity: .8;
    }
    &.TW {
        background-color: #5BA525;
    }
    &.US {
        background-color: #346AA1;
    }
    &.CA {
        background-color: #E96D6F;
    }
    &.UK {
        background-color: #11226C;
    }
    &.NO {
        background-color: #C8B54C;
    }
    &.MX {
        background-color: green;
    }
    &.DE {
        background-color: #3e3838;
    }
}
.cardTitle {
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
    margin-bottom: 18px;
}
.cardSubtitle,
.cardText {
    color: #e3e3e3;
    font-size: 1rem;
}