@mixin phone {
    @media screen and (max-width: 480px) {
      @content;
    }
  }

  @mixin notPhone {
      @media screen and (min-width: 481px) {
        @content;
      }
  }

  @mixin md {
    @media screen and (max-width: 992px) {
      @content;
    }
  }

  @mixin xs {
    @media screen and (max-width: 768px) {
      @content;
    }
  }

  @mixin lg {
    @media screen and (max-width: 1200px) {
      @content;
    }
  }
