
.title {
    position: relative;
    &::after{
        content: '';
        height: 2px;
        background-color: black;
        position: absolute;
        bottom: -15px;
        width: 40px;
        left: calc(50% - 20px);
    }
}