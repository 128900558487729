@import "../styles/mixin.scss";

.footer {
    padding: 60px 0;
    background-color: #424242;
    color: #e0e0e0;
    min-height: 10vh;
}

        .footer > .inner > * > :last-child {
            margin-bottom: 0;
        }

        .footer > .inner section:nth-child(1) {
            width: calc(66% - 2.5em);
            margin-right: 2.5em;
        }

        .footer > .inner section:nth-child(2) {
            width: calc(33% - 2.5em);
            margin-left: 2.5em;
        }

        .footer > .inner .copyright {
            width: 100%;
            padding: 0;
            list-style: none;
            font-size: 15px;
            margin: 20px 0;
            text-align: right;
        }

            .footer > .inner .copyright a {
                color: inherit;
            }

            .footer > .inner .copyright li {
                display: inline-block;
                border-left: solid 1px rgba(88, 88, 88, 0.15);
                line-height: 1;
                padding: 0 0 0 1em;
                margin: 0 0 0 1em;
            }

                .footer > .inner .copyright li:first-child {
                    border-left: 0;
                    padding-left: 0;
                    margin-left: 0;
                }

    @media screen and (max-width: 1280px) {

        .footer {
            padding: 5em 0 3em 0 ;
        }

            .footer > .inner section:nth-child(1) {
                width: calc(66% - 1.25em);
                margin-right: 1.25em;
            }

            .footer > .inner section:nth-child(2) {
                width: calc(33% - 1.25em);
                margin-left: 1.25em;
            }

    }

    @media screen and (max-width: 980px) {

        .footer > .inner section:nth-child(1) {
            width: 66%;
            margin-right: 0;
        }

        .footer > .inner section:nth-child(2) {
            width: calc(33% - 2.5em);
            margin-left: 2.5em;
        }

    }

    @media screen and (max-width: 736px) {

        .footer {
            padding: 0 ;
        }

            .footer > .inner {
                -moz-flex-direction: column;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
            }

                .footer > .inner section:nth-child(1) {
                    width: 100%;
                    margin-right: 0;
                    margin: 3em 0 0 0;
                }

                .footer > .inner section:nth-child(2) {
                    -moz-order: -1;
                    -webkit-order: -1;
                    -ms-order: -1;
                    order: -1;
                    width: 100%;
                    margin-left: 0;
                }

                .footer > .inner .copyright {
                    margin-top: 1em;
                }

    }

    @media screen and (max-width: 480px) {

        .footer >.inner {
            padding-bottom: 20px;
            text-align: center;
        }

        .footer > .inner .copyright {
            margin-top: 1em;
            text-align: center;
        }

            .footer > .inner .copyright li {
                border-left: 0;
                padding-left: 0;
                margin: 0.75em 0 0 0;
                display: inline-block;
                padding-right: 10px;
                line-height: inherit;
            }

                .footer > .inner .copyright li:first-child {
                    margin-top: 0;
                }

    }