.primary {
    background: #458FF6;
    border-radius: 55px;
    padding: 14px 60px;
    font-size: 18px;
    color: white;
    &:hover {
        text-decoration: none;
        color: white;
        opacity: 0.8;
    }
}