
.locationTitle {
    list-style-type: none;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.1;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 20px;
    @mixin phone {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.cardStation {
    text-align: left;
    a,
    a:hover,
    a:active {
        text-decoration: none;
    }
}